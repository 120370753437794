.placebet-content {
  display: flex;
  flex-direction: column;
  color: white;
  gap: 20px;
  @media screen and (min-width: 768px) {
    margin: 20px 40px;
  }
  li {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 24px;
    @media screen and (max-width: 767px) {
      font-size: 16px;
    }
  }
}
.question {
  color: #0f4c41;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 90px;
  background-color: #f1ef26;
  @media screen and (max-width: 767px) {
    width: 60px;
    height: 60px;
    svg {
      width: 30px;
    }
    font-size: 40px;
  }
}
