.header-wrapper {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  .alert-bar {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
    color: white;
    img {
      width: 30px;
      margin-right: 10px;
    }
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 80px;
    background-color: rgba(0, 0, 0, 0.35);
    height: 100px;
    width: 100%;
    .logo {
      img {
        height: 80px;
      }
    }

    .btn-connect {
      float: right;
      height: 37px;
      margin: 16px 0px 16px 0px;
      background-color: #00815d;
      color: white;
      border-radius: 20px;
      font-size: 12pt;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 5px solid #fff500;
      padding: 0 20px;
    }
    @media only screen and (max-width: 767px) {
      background-color: #0f2d40;
      .btn-connect {
        border-width: 3px;
      }
      &.ant-layout-header {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }
}
