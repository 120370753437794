.counter {
  position: relative;
  @media screen and (max-width: 1024px) {
    &:first-child {
      margin-bottom: 10px;
    }
  }
  .title {
    position: absolute;
    left: 0;
    top: -80px;
    font-family: Cinzel;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    white-space: nowrap;
    @media screen and (max-width: 1024px) {
      font-size: 20px;
      top: -60px;
    }
  }
  .body {
    margin-top: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 20px;
    &.stake {
      .box::after {
        background: #424a4d;
      }
    }
    &.sale {
      .box::after {
        background: #0f4c41;
      }
    }
    .box {
      width: 140px;
      position: relative;
      .detail {
        position: absolute;
        width: 100%;
        height: 100%;
        // height: 140px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: white;
        span.number {
          font-family: NewsGoth BT;
          font-style: normal;
          font-weight: bold;
          font-size: 48px;
          line-height: 58px;
          text-align: center;
        }
        span.unit {
          font-family: NewsGoth BT;
          font-style: normal;
          font-weight: normal;
          font-size: 24px;
          line-height: 24px;
          text-align: center;
        }
      }
    }
    @media screen and (max-width: 767px) {
      margin-bottom: 10px;
      gap: 10px;
      .box {
        .detail {
          span.number {
            font-size: 30px;
            line-height: 30px;
          }
          span.unit {
            font-size: 20px;
          }
        }
      }
    }
  }
  .box:after {
    content: "";
    display: block;
    padding-bottom: 100%;
    box-shadow: 0px 40px 40px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
  }
}
