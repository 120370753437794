.tier-card {
  width: 380px;
  margin-top: 48px;
  @media screen and (max-width: 500px) {
    width: 90%;
  }
  .card-header {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: #0f4c41;
    height: 104px;
    display: flex;
    align-items: center;
    padding: 24px;
    font-size: 35px;
    line-height: 42px;
    color: white;
    position: relative;
    .card-avatar {
      position: absolute;
      transform: translate(-50%, -50%);
      top: 0;
      left: 50%;
    }
    .card-title {
      position: absolute;
      transform: translate(-50%, 50%);
      bottom: 0;
      left: 50%;
      padding: 5px 25px;
      background-color: #0f4c41;
      border-radius: 50px;
      border: 5px solid #fff500;
      text-transform: uppercase;
      font-family: Molot;
      font-weight: 300;
      font-size: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    @media screen and (max-width: 767px) {
      height: 80px;
      .card-avatar {
        width: 50%;
      }
      .card-title {
        width: 50%;
        height: 50%;
        font-size: 16px;
        border-width: 3px;
      }
    }
  }
  .card-body {
    background-color: #00815d;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    padding: 24px;
    padding-top: 48px;
    .row {
      display: flex;
      justify-content: space-between;
      color: white;
      border-bottom: 2px solid #ffffff3f;
      font-size: 17px;
      padding-bottom: 15px;
      margin-bottom: 15px;
      @media screen and (max-width: 767px) {
        padding-bottom: 10px;
        margin-bottom: 10px;
        span {
          line-height: 20px;
          font-size: 14px;
        }
      }
      p {
        margin-bottom: 0;
        font-weight: normal;
      }
      span {
        max-width: 50%;
      }
      span:last-child {
        text-align: right;
        font-weight: bold;
      }
    }
    button {
      margin-top: 25px;
      border-radius: 10px;
      color: #0f4c41;
      font-size: 25px;
      font-weight: bold;
      height: 59px;
      display: flex;
      align-items: center;
      justify-content: center;
      @media screen and (max-width: 767px) {
        height: 40px;
        font-size: 20px;
      }
    }
  }
}
