.ant-modal {
  width: 768px !important;
  position: relative;
  @media screen and (max-width: 900px) {
    width: 80% !important;
  }

  .ant-modal-content {
    background-color: transparent;
    .ant-modal-header {
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      border: none;
      background-color: #0f4c41;
      height: 110px;
      display: flex;
      align-items: center;
      .ant-modal-title {
        color: white;
        font-family: Molot;
        font-style: normal;
        font-weight: normal;
        font-size: 48px;
        margin-left: 80px;
        @media screen and (max-width: 768px) {
          font-size: 28px;
          margin-left: 40px;
        }
      }
      @media screen and (max-width: 768px) {
        height: 70px;
      }
    }
    .ant-modal-close {
      margin: 20px 20px;
      @media screen and (max-width: 767px) {
        margin: 0;
      }
      .ant-modal-close-x {
        span {
          color: white;
          font-size: 40px;
          @media screen and (max-width: 767px) {
            font-size: 25px;
          }
        }
      }
    }
    .ant-modal-body {
      background-color: #00815d;
    }
    .ant-modal-footer {
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      border: none;
      background-color: #0f4c41;
    }
  }

  .type-icon {
    position: absolute;
    top: -15px;
    left: -40px;
    @media screen and (max-width: 767px) {
      top: -10px;
      left: -15px;
    }
  }
}

.noFooter {
  .ant-modal-body {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  .ant-modal-footer {
    display: none;
  }
}
