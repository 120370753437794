
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  background: url(./assets/images/bg-LOA.jpg);
  background-size: cover;
  background-attachment: fixed;
}

@media only screen and (max-width: 767px) {
  body {
    background: none;
  }
}

@font-face {
  font-family: "NewsGoth BT";
  src: url("assets/fonts/News Gothic BT/News Gothic BT.eot");
  src: url("assets/fonts/News Gothic BT/News Gothic BT.woff2") format("woff2"),
    url("assets/fonts/News Gothic BT/News Gothic BT.woff") format("woff"),
    url("assets/fonts/News Gothic BT/News Gothic BT.ttf") format("truetype"),
    url("assets/fonts/News Gothic BT/News Gothic BT.svg") format("svg");
}

@font-face {
  font-family: "Molot";
  font-style: normal;
  font-weight: normal;
  src: url(assets/fonts/Molot.woff) format("woff");
}

@font-face {
  font-family: "Cinzel";
  font-style: normal;
  font-weight: normal;
  src: url(assets/fonts/Cinzel-Regular.ttf) format("truetype");
}
