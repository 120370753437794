.content {
  padding: 0 80px;
  @media screen and (max-width: 767px) {
    background-color: #0f2d40;
  }
  @media screen and (max-width: 1024px) {
    padding: 0 36px;
  }
  .hero {
    margin-top: 150px;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 50%;
    .bg {
      display: none;
    }
    @media screen and (max-width: 767px) {
      margin-top: 100px;
      .bg {
        display: block;
        width: calc(100% + 72px);
        &.purchase-alert {
          margin-top: 50px;
        }
      }
      img.logo {
        margin-top: -93px;
      }
    }
    @media screen and (max-width: 1024px) {
      width: 100%;
    }
    img.logo {
      width: 300px;
    }
    h1 {
      color: #a9deee;
      font-family: "Cinzel", serif;
      font-size: 64px;
      font-style: normal;
      font-weight: 800;
      line-height: 100%;
      text-align: center;
      @media screen and (max-width: 1024px) {
        font-size: 50px;
        max-width: 80%;
      }
    }
    h3 {
      color: white;
      max-width: 860px;
      text-align: center;
      font-family: NewsGoth BT;
      font-weight: normal;
      font-size: 18px;
      @media screen and (max-width: 1024px) {
        max-width: 80%;
        font-size: 15px;
      }
    }
    button {
      margin-top: 20px;
      padding: 0 30px;
      font-weight: 800;
      color: #0f4c41;
      border-radius: 15px;
    }
  }
  .counter-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 50px;
    margin-top: 40px;
    margin-bottom: 40px;
    .counter {
      width: 50%;
    }
    @media screen and (max-width: 1024px) {
      flex-direction: column;
      gap: 0px;
      .counter {
        width: 100%;
      }
    }
  }
  .card-wrapper {
    display: flex;
    justify-content: center;
    gap: 50px;
    margin-top: 50px;
    padding-bottom: 100px;
    flex-wrap: wrap;
  }
}
